<template>
	<div>

		<template>
			<div id="breadcrumb-alignment" class="mb-1">
				<div class="d-flex justify-content-start breadcrumb-wrapper">
					<b-breadcrumb :items="breadCrumb()"/>
				</div>
			</div>
		</template>

		<b-card>
			<b-row v-if="checkPermission('custom-forms', 'Update Item')">
				<b-col md="12">
					<div class="demo-inline-spacing float-right ">
						<b-button variant="danger" class="mt-0 mb-1" @click="openLocations()" v-if="disabledOptions == false">
							<feather-icon icon="TargetIcon" class="mr-25"/>
							<span>Locations</span>
						</b-button>
					</div>
				</b-col>
			</b-row>

			<b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
				<div class="alert-body">{{error_message}}</div>
			</b-alert>

			<b-row>
				<b-col md="12">
					<h4 class="card-title float-left">Floor Traps</h4>
				</b-col>
			</b-row>

			<b-row>
				<b-col md="3">
					<b-form-group label="Project Site" class="required">
						<b-form-select v-model="site" @change="siteChange" :disabled="disabledOptions">
							<b-form-select-option value="" disabled>Select</b-form-select-option>
							<b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
						</b-form-select>
					</b-form-group>
				</b-col>
				<b-col md="3">
					<b-form-group label="Shift" class="required">
					<b-form-select v-model="shift" @change="getLocationData()" :disabled="disabledOptions">
						<b-form-select-option value="" disabled>Select</b-form-select-option>
						<b-form-select-option :value="shift._id" v-for="shift in shifts" :key="shift._id">{{shift.shift | capitalize}}</b-form-select-option>
					</b-form-select>
					</b-form-group>
				</b-col>
				<b-col md="3" >
					<b-form-group label="Date" class="required"> 
						<b-form-datepicker v-model="date"
							:date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
							locale="en" @input="getLocationData()"
							:disabled="disabledOptions"
						/>
					</b-form-group>
				</b-col>
				<b-col md="3" >
					<b-form-group label="Checked By" class="required">
						<b-form-input placeholder="3 to 50 characters" v-model="checked_by" autocomplete="off" :disabled="disabledOptions"/>
					</b-form-group>
				</b-col>
			</b-row>
		</b-card>
		
		<b-form @submit="formSubmit">

			<b-card no-body class="mb-1" v-for="(list,i) in checklistData" :key="i">
				<b-table responsive show-empty
					ref = "refUserListTable"
					class = "position-relative"
					primary-key = "id"
					empty-text = "No records found"
					:fields = "tableColumns"
					:items = "list.data"
					v-if="site != '' && shift != ''"
				>
					<template slot="thead-top" slot-scope="data">
						<tr>
							<th colspan="2"> 
								<b-form-select v-model="list.building" @change="buildingChange(list.building,i)" :disabled="(checkdisabled() && (checklistData.length > 0))">
									<b-form-select-option value="" disabled>Select Building</b-form-select-option>
									<b-form-select-option :value="building._id" v-for="building in buildings" :key="building._id" :disabled="buildingDisable(building._id)">{{building.name | capitalize}}</b-form-select-option>
								</b-form-select>
							</th>
							<th colspan="4">
								<b-button size="sm" variant="danger" class="mr-1 float-right" @click="removeData(i)" :disabled="checkdisabled()"
									v-if="checklistData.length > 0 && checklistData[0].data.length > 0 && checklistData[i].db_building == false"
								> Remove
								</b-button>
								<b-button size="sm" variant="danger" class="mr-1 float-right" @click="removeDBBuilding(i,checklistData[i])" :disabled="checkdisabled()"
									v-if="checklistData.length > 0 && checklistData[0].data.length > 0 && checklistData[i].db_building == true"
								> Remove
								</b-button>
							</th>
						</tr>
					</template>
					
					<template #cell(level)="items">
						<p v-if="items.item.view_level_location == 'yes'">{{items.item.level}}</p>
					</template>

					<template #cell(location)="items">
						<p v-if="items.item.view_level_location == 'yes'">{{items.item.location}}</p>
					</template>

					<template #cell(toilet_type)="items">
						<p>{{items.item.toilet_type}}</p>
					</template>
					
					<!-- <template #cell(toilet_type)="items">
						<b-form-select v-model="items.item.toilet_type">
							<b-form-select-option value="">Select Toilet Type</b-form-select-option>
							<b-form-select-option v-for="(type, ind) in items.item.toilet_types" :key="ind" :value="type">{{type}}</b-form-select-option>
						</b-form-select>
					</template> -->

					<template #cell(total_quantity)="items">
						<b-form-input placeholder="" v-model="items.item.total_quantity" type="text" autocomplete="off" @input="CalculateTotalActualQuantity()" @keypress="isNumber($event)"/>
					</template>

					<template #cell(defect)="items">
						<b-form-input placeholder="" v-model="items.item.defect" type="text" autocomplete="off" @input="CalculateTotalDefect()" @keypress="isNumber($event)"/>
					</template>

					<template #cell(description)="items">
						<b-form-input placeholder="" v-model="items.item.description" autocomplete="off"/>
					</template>
				</b-table>
			</b-card>

			<b-card no-body class="mb-1" >
				<b-table show-empty empty-text = "No records found" :fields = "tableColumns2" :items = "items2" 
					ref = "totalTable" v-if="checklistData.length > 0 && checklistData[0].data.length > 0"
				>
					<template #cell(label)="items2">
						<p>{{items2.item.building_name}}</p>
					</template>   

					<template #cell(total_defect)="items2">
						<p>{{items2.item.total_defect}}</p>
					</template>   

					<template #cell(total_actual_quantity)="items2">
						<p>{{items2.item.total_actual_quantity}}</p>
					</template>
				</b-table>
			</b-card>
			
			<b-card class="mt-1" v-if="checklistData.length > 0 && checklistData[0].data.length > 0">
				<b-row>
					<b-col md="3"></b-col>

					<b-col md="3">
						<b-form-group label="Start Time" class="required">
							<flat-pickr v-model="startTime" class="form-control" :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"/>
						</b-form-group>
					</b-col>

					<b-col md="3">
						<b-form-group label="End Time" class="required">
							<flat-pickr v-model="endTime" class="form-control" :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"/>
						</b-form-group>
					</b-col>
					
					<b-col md="3">
						<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="mr-1 float-right" @click="addMore" v-if="site != ''" :disabled="checkdisabled()"
						> Add More
						</b-button>
					</b-col>
					
				</b-row>

				<b-row>
					<b-col cols="12" class="mb-2 mt-2">
						<div class="pb-2">
							<b-form-group label="Signature" class="required" style="font-size:20px;color:white;text-align:center"/>
							<center>
								<div @click="openSignature('supervisor')" v-if="crew_signature.image == null">
									<b-avatar ref="supervisorS" :src="doc_icon" variant="light-info" size="200px" rounded />
								</div>
								<div class="d-flex flex-wrap">
									<input ref="supervisorSInput" type="file" class="d-none" @input="supervisorImageUpload" >
								</div>
								<img :src="crew_signature.image" width="200px" class="rounded" height="180" v-if="crew_signature.image != null" style="background: #ffffff">
								
							</center>
							<center>
								<b-button variant="danger" class="mt-1 ml-2" v-if="crew_signature.image != null" @click="removeSign('supervisor')" :disabled="disabledOptions">
									<feather-icon icon="XIcon" />
								</b-button>
							</center>
						</div>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" class = "mr-1" variant="outline-secondary" @click="goBack()">
							Cancel
						</b-button>

						<b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" variant = "danger" class = "mr-1" @click="discardItem(id)" v-if="id != null && typeof $route.params.id == 'undefined'">
							Discard Draft
						</b-button>

						<b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" variant = "primary" class = "mr-1" type="submit" @click="status = 'draft'" v-if="typeof $route.params.id == 'undefined'">
							{{ id == null ? 'Save as Draft' : 'Update Draft'}}
						</b-button>

						<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mr-1" type="submit" @click="status = 'completed'">
							{{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Save' }}
						</b-button>

						<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1" v-if="typeof $route.params.id != 'undefined' && ledit == false" @click="openSignature('approver')">
							{{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Approve' }}
						</b-button>
					</b-col>
				</b-row>

			</b-card>

		</b-form>    

		<b-modal id="signatureModel" ref="signatureModel" centered title="Signature" 
			no-close-on-backdrop hide-footer @hide="hideSignature" @show="hideSignature" 
		>
		
			<div v-if="show_default == true">
				<b-row>
					<b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
						<center>
							<img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
						</center>
					</b-col>
				</b-row>

				<b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
					<b-col md="12">
							<date-picker v-model="approve_signature_time" format="DD MMM YYYY HH:mm" type="datetime" placeholder="Select"
							valueType="format" :clearable=false :confirm=true lang="en"
							:class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
							:disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'administrator' ? false : true"
							:disabled-date="disabledDate"
						></date-picker>
					</b-col>
				</b-row>

				<b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
					<b-col md="12">
						<b-form-group label="Remarks" class="">
							<b-form-textarea placeholder="" rows="3" v-model="approve_remark"/>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row class="mt-2">
					<b-col md="6">
						<b-button variant="secondary" class="mt-0 w-100" @click="show_default = false">
							<span class="text-nowrap">Add New Signature</span>
						</b-button>
					</b-col>

					<b-col md="6">
						<b-button variant="warning" class="mt-0 float-right w-100" @click="saveDefault()">
							<span class="text-nowrap">Submit</span>
						</b-button>
					</b-col>
				</b-row>
			</div>

			<div v-else>
				<b-row>
					<b-col md="12">
						<VueSignaturePad id="signature" width="100%" height="300px" ref="signaturePad" 
							:options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
						/>
					</b-col>
				</b-row>

				<b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
					<b-col md="12">
						<date-picker v-model="approve_signature_time"  format="DD MMM YYYY HH:mm"  type="datetime" placeholder="Select" valueType="format"
						:clearable=false :confirm=true lang="en" :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
						:disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'administrator' ? false : true"
						:disabled-date="disabledDate"
						></date-picker>
					</b-col>
				</b-row>

				<b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
					<b-col md="12">
						<b-form-group label="Remarks" class="">
							<b-form-textarea placeholder="" rows="3" v-model="approve_remark"/>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row class="mt-2">

					<b-col md="3" v-if="typeof $route.params.id == 'undefined'">
						<b-button variant="primary" class="mt-0 w-100" @click="uploadSign()">
							<span class="text-nowrap">Upload</span>
						</b-button>
					</b-col>

					<b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">
						<b-button variant="secondary" class="mt-0 w-100" v-if="having_default == true" @click="show_default = true">
							<span class="text-nowrap">Default</span>
						</b-button>
					</b-col>

					<b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">
						<b-button variant="danger" class="mt-0 w-100" @click="clearSignature">
							<span class="text-nowrap">Clear</span>
						</b-button>
					</b-col>

					<b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">
						<b-button variant="warning" class="mt-0 w-100" @click="saveSignature">
							<span class="text-nowrap">Submit</span>
						</b-button>
					</b-col>
				</b-row>
			</div>
		</b-modal> 

	</div>
</template>

<script>
	import {
		BCard, BButton, BAvatar, BRow, BCol, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,BFormCheckbox,BFormDatepicker, BTabs, BTab, BBreadcrumb
	} from 'bootstrap-vue'
	import { avatarText } from '@core/utils/filter'
	import Ripple from 'vue-ripple-directive'
	import { GET_API, POST_API } from "../../../store/actions.type"
	import 'viewerjs/dist/viewer.css'
	import Datepicker from 'vuejs-datepicker';
	import flatPickr from 'vue-flatpickr-component'
	import vSelect from 'vue-select'
	var moment = require('moment-timezone');
	import Bus from "../../../event-bus";
	import CrewSignature from './CrewSignature.vue';
	import DatePicker from 'vue2-datepicker';
	import 'vue2-datepicker/index.css';

	export default {
		components: {
			BCard, BButton, BRow, BCol, BAvatar, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,Datepicker,BFormCheckbox,flatPickr,vSelect,BFormDatepicker,CrewSignature, BTabs, BTab, BBreadcrumb, DatePicker
		},
	
		data() {
			return {
			draftData:null,
			date:moment().tz('Asia/Singapore').format('YYYY-MM-DD'),
			
			id: null,
			site:'',
			checked_by:this.$store.getters.currentUser.full_name,
			building:'',
			buildings:[],
			sites:[],
			shifts:[],
			shift:'',
			items:[],
			items2:[{building_name: '', total_defect: 0, total_actual_quantity: 0}],
			status: 'completed',

			openedSignModel:null,
			showButton: false,
			startTime : '',
			endTime: '',
			crew_signature : {
				image:null,
				name:'',
				type: 'supervisor',
				default:'no'
			},
			showDismissibleAlert:false,
			error_message:'',
			doc_icon: require('@/assets/images/doc.png'),
			checklistData:[],
			tempSign : {
				image:null,
				name:'',
			},
			show_default:false,
			having_default:false,

			tableColumns:[
				{ key: 'level', label: 'Level', sortable: false , thStyle:  {width: '12%'}},
				{ key: 'location', label: 'Location', sortable: false , thStyle:  {width: '26%'}},
				{ key: 'toilet_type', label: 'Toilet Type', sortable: false , thStyle:  {width: '12%'}},
				{ key: 'total_quantity', label: 'Actual Quantity', sortable: false , thStyle:  {width: '16%'}},
				{ key: 'defect', label: 'Defect', sortable: false , thStyle:  {width: '16%'}},
				{ key: 'description', label: 'Description', sortable: false , thStyle:  {width: '18%'}},
			],
			tableColumns2:[
				{ key: 'label', label: '', sortable: false , thStyle:  {width: '50%'}},
				{ key: 'total_actual_quantity', label: 'Total Actual Quantity', sortable: false , thStyle:  {width: '16%'}},
				{ key: 'total_defect', label: 'Total Defect', sortable: false , thStyle:  {width: '16%'}},
				{ key: 'test2', label: '', sortable: false , thStyle:  {width: '18%'}},
			],
			month:moment().format('MMMM'),
			year:parseInt(moment().format('YYYY')),
			yearData:[],
			// new vars
			disabledOptions:false,
			supervisorSign : {
				image:null,
				name:'',
				type: 'supervisor',
				default:'no'
			},
			approve_signature_time:null,
			approve_remark:'',
			ledit : false,
			}
		},
		directives: {
			Ripple,
		},

		methods: {
			showAlert() {
				Swal.fire({
					position: 'center',
					icon: 'error',
					text: this.error_message,
					showConfirmButton: false,
					timer: 3000
				})
			},
			
			allSites(){
				return this.$store.dispatch(POST_API, {
					data:{
						role:this.$store.getters.currentUser.role,
					},
					api: '/api/all-sites'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
						this.showDismissibleAlert = true;
						window.scrollTo(0,0);
					} else {
						this.showDismissibleAlert = false;
						var data  = this.$store.getters.getResults.data;
						
						this.sites = data;
						if (this.sites.length == 1 && typeof this.$route.params.id == 'undefined') {
							this.site = this.sites[0]._id;
							this.siteChange();
						}
						return this.sites;
					}
				});
			},

			formSubmit(e){
				e.preventDefault();
				if(this.status == 'draft'){
					this.actualForm();            
				} else {    
					this.decisionAlert('Are you sure want to submit this report ?')
					.then(result => {
						if (result.value) {
							this.actualForm();            
						}
					})
				}
			},

			actualForm(){
				return this.$store.dispatch(POST_API, {
					data:{
						id : this.id,
						date : this.date,
						site :this.site,
						checked_by : this.checked_by,
						shift : this.shift,
						checklistData : this.checklistData,
						status: this.status,
						crew_signature: this.crew_signature,
						startTime: this.startTime,
						endTime: this.endTime,
						remarks: this.remarks,
						form_id:this.$route.params.id,
						approve_signature: this.supervisorSign,
						approve_signature_time: this.approve_signature_time,
						approve_remark: this.approve_remark,
						role:this.$store.getters.currentUser.role,
					},
					api : '/api/submit-floor-traps'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message        = this.$store.getters.getErrors;
						this.errorAlertCF()
					} else {
						this.showDismissibleAlert = false;
						var data = this.$store.getters.getResults.data;
						this.successAlert().then((result) => {
							if (this.sites.length == 1) {
								this.site = this.sites[0]._id;
							}else{
								this.site = '';
							}
							this.siteChange();

							if (data != null) {
								window.open(data, '_blank');
							}

							localStorage.setItem('routeCFCheck','no');
							Bus.$emit('counter_update');

							if(this.$route.params.id){
								this.$router.push({name:'floor-traps-report'})
							}else{
								this.$router.push({ name:'custom-forms' })
							}
						});
					}
				});
			},
			
			getLocationData(){
			localStorage.setItem('routeCFCheck','no');
				return this.$store.dispatch(POST_API, {
					data:{
						site:this.site != '' ? this.site : null,
						shift:this.shift != '' ? this.shift : null,
						date:this.date,
					},
					api: '/api/floor-traps-locations-data'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
						this.showDismissibleAlert = true;
						window.scrollTo(0,0);
					} else {
						this.showDismissibleAlert = false;
						var data  = this.$store.getters.getResults.data;
						this.items2 = [];
						this.id = data.id;
						this.checklistData = data.checkListData;
						this.crew_signature = data.crew_signature;
						this.startTime = data.startTime;
						this.endTime = data.endTime;

						if (this.checklistData.length != 0) {
							this.CalculateTotalDefect();
							this.CalculateTotalActualQuantity();
						}
						return this.checklistData;
					}
				});
			},

			getShiftData(){
				return this.$store.dispatch(POST_API, {
					data:{
						site:this.site != '' ? this.site : null,
					},
					api: '/api/site-shifts'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
						this.showDismissibleAlert = true;
						window.scrollTo(0,0);
					} else {
						this.showDismissibleAlert = false;
						var data  = this.$store.getters.getResults.data;
						
						this.shifts = data;
						return this.shifts;
					}
				});
			},
		
			siteChange(){
				localStorage.setItem('routeCFCheck','no');
				this.checklistData = [];
				this.shifts = [];
				this.shift = '';
				if (this.site != '') {
					this.getShiftData();
					this.getBuildings();
				}
			},

			getdraftData(){
				return this.$store.dispatch(POST_API, {
					data:{
						site:this.site != '' ? this.site : null,
						date:this.date,
					},
					api: '/api/draft-floor-traps-report-data'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
						this.showDismissibleAlert = true;
						window.scrollTo(0,0);
					} else {
						this.showDismissibleAlert = false;
						var data  = this.$store.getters.getResults.data;
						this.draftData = data;
						
						return this.draftData;
					}
				});
			},

			buildingChange(id,i){
				this.checklistData[0].building != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');
				if (id != '') {
					return this.$store.dispatch(POST_API, {
						data:{
							building:id,
							site:this.site
						},
						api: '/api/floor-traps-building-location-data'
					})
					.then(() => {
						if (this.$store.getters.containsErrors) {
							this.error_message = this.$store.getters.getErrors;
							this.showDismissibleAlert = true;
							window.scrollTo(0,0);
						} else {
							this.showDismissibleAlert = false;
							var data  = this.$store.getters.getResults.data;
							this.checklistData[i].data = data;
							if (this.checklistData[i].data.length != 0) {
								this.CalculateTotalDefect();
								this.CalculateTotalActualQuantity();
							}
							return this.checklistData;
						}
					});
				}else{
					this.checklistData[i].data = [];
				}
			},

			addMore(){
				var order = 0;
				if (this.checklistData.length > 0) {
					var lastIndex = this.checklistData.length - 1;
					order = this.checklistData[lastIndex].order + 1;
				}
				var obj = {
					building              : '',
					data                  : [],
					order                 : order,
					total_defect          : '',
					total_actual_quantity : '',
					"db_building"         : false,
				};
				this.checklistData.push(obj);
			},

			removeData(index){
				this.checklistData.splice(index,1);
				this.items2.splice(index,1);
				if (this.checklistData.length == 0) {
					this.addMore();
				}
			}, 

			removeDBBuilding(index,item){
				var msg = 'Are you sure want to remove this building record?';

				this.decisionAlert(msg)
				.then(result => {
					if (result.value) {
						return this.$store.dispatch(POST_API, {
							data:{
								building_id : item.building,
								date : this.date,
								site :this.site,
								shift : this.shift,
							},
							api : "/api/remove-ft-db-building",
						})
						.then(() => {
							if (this.$store.getters.containsErrors) {
								this.error_message = this.$store.getters.getErrors;
								this.showAlert();
							} else {
								this.successAlert()
								.then(() => {
									this.checklistData.splice(index,1);
									if (this.checklistData.length == 0) {
										this.addMore();
									}
									var temp = [];
									this.checklistData.forEach((ele, ind) => {
										if(ele.building_name == this.items2[ind].building_name){
											temp.push(this.items2[ind])
										}
									});
									this.items2 = temp;
									this.$refs.totalTable.refresh();
								});
							}
						});
					}
				})
				.catch(err => {
					console.log(err);
				})
			},

			getBuildings(){
				return this.$store.dispatch(POST_API, {
					data:{
						role:this.$store.getters.currentUser.role,
						site:this.site,
						form:'Floor Traps'
					},
					api: '/api/all-buildings'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
					} else {
						this.buildings = this.$store.getters.getResults.data;            
						return this.buildings;
					}
				});
			},

			openLocations(){
				this.$router.push({ name: 'hand-sanitizer-locations',params:{site_id:this.site != '' ? this.site : 'all-sites',id:'all-building',form:'Floor_Traps'}})
			},

			refreshData(){
				this.flag = false;
				this.id   = null;
				this.site = '';

				this.checked_by    = this.$store.getters.currentUser.full_name;
				this.date          = moment().tz('Asia/Singapore').format('YYYY-MM-DD');
				this.status        = 'completed';
				this.checklistData = [];

				this.crew_signature = {
					image   : null,
					name    : '',
					type    : 'supervisor',
					default : 'no'
				};
				this.startTime = '';
				this.endTime = '';
				
				this.openedSignModel=null;
			},

			discardItem(id){
				var msg = 'Are you sure want to discard this record?';

				this.decisionAlert(msg)
				.then(result => {
					if (result.value) {
						return this.$store.dispatch(POST_API, {
							data:{
								id     : id,
								status : 'deleted'
							},
							api : "/api/change-status-floor-traps-report-data",
						})
						.then(() => {
							if (this.$store.getters.containsErrors) {
								this.error_message = this.$store.getters.getErrors;
								this.showAlert();
							} else {
								localStorage.setItem('routeCFCheck','no');
								this.successAlert()
								.then(() => {
									if(this.$route.params.id){
										this.$router.push({name:'floor-traps-report'})
									}else{
										this.$router.push({ name:'custom-forms' })
									}
								});
							}
						});
					}
				})
				.catch(err => {
					console.log(err);
				})
			},

			supervisorImageUpload(event){
				var input = event.target;
				var files = event.target.files

				if (input.files && input.files[0]) {
					var reader = new FileReader();
					reader.readAsDataURL(input.files[0]);
					var image = input.files[0];

					if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
						Swal.fire({
							position: 'center',
							icon: 'warning',
							title: 'Please upload .jpg, .jpeg, .jpg images only',
							showConfirmButton: false,
							timer: 1500
						});
						this.$refs.supervisorSInput.value=null;
					} else if (image.size > 2097152) {
						Swal.fire({
							position: 'center',
							icon: 'warning',
							title: 'Maximum 2 MB files allowed to be upload.',
							showConfirmButton: false,
							timer: 1500
						})
						this.$refs.supervisorSInput.value=null;
					} else {
						reader.onload = (e) => {
							this.crew_signature.image = e.target.result;
							this.crew_signature.name = image.name;
							this.crew_signature.default = 'no';
						}
					}
				}
			},

			removeSign(sign){
				this.crew_signature.image = null;
				this.crew_signature.name = '';
				this.crew_signature.default = 'no';
			},

			useDefault(){
				return this.$store.dispatch(POST_API, {
					data:{
						id:this.$store.getters.currentUser._id
					},
					api: '/api/get-default-signature'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
						this.showDismissibleAlert = true;
						window.scrollTo(0,0);
					} else {
						this.showDismissibleAlert = false;
						var data  = this.$store.getters.getResults.data;

						if (data == null || data.default_signature == null) {
							this.having_default = false;						
						}else{
							this.tempSign = {
								image : data.default_signature,
								name : 'signature.png',
							}
							this.having_default = true;
						}
					}
				});
			},

			uploadSign(){
				this.$refs['supervisorSInput'].click();
				this.$refs['signatureModel'].hide();
			},

			openSignature(type){
				this.approve_signature_time = moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY HH:mm');
				if (this.openedSignModel == null) {
					if (this.having_default == true) {
						this.show_default = true;
					}else{
						this.show_default = false;
					}
					this.$refs['signatureModel'].show();
					this.openedSignModel = type;
				}
			},

			hideSignature(){
				this.openedSignModel = null;
			},

			clearSignature(){
				this.$refs.signaturePad.clearSignature();
			},

			saveSignature(){
				const { isEmpty, data } = this.$refs.signaturePad.saveSignature();				
				if (isEmpty) {
					Swal.fire({
						position: 'center',
						icon: 'warning',
						title: 'Signature is required',
						showConfirmButton: false,
						timer: 1500
					})
				}else{
					if (this.openedSignModel == 'supervisor') {
						this.crew_signature.image = data;
						this.crew_signature.name = 'signature.png';
						this.crew_signature.type = 'supervisor';
						this.crew_signature.default = 'no';
					} else {
						this.supervisorSign.image = data;
						this.supervisorSign.name = 'signature.png';
						this.supervisorSign.type = 'supervisor';
						this.supervisorSign.default = 'no';
						this.status = 'approved';
						this.actualForm();
					}
					this.$refs['signatureModel'].hide();
				}
			},

			saveDefault(){
				if (this.openedSignModel == 'supervisor') {
					this.crew_signature = {
						image : this.tempSign.image,
						name : 'signature.png',
						default:'yes'
					}
					}else{
					this.supervisorSign = {
						image : this.tempSign.image,
						name : 'signature.png',
						default:'yes',
						type:'supervisor'
					}
					this.status = 'approved';
					this.actualForm();
				}
				this.$refs['signatureModel'].hide();
			},

			breadCrumb(){
				if(this.$route.params.id){
					var item = [{
						to:{name:'client-dashboard'},
						text: 'Dashboard',
					},{
						to:null,
						text: 'Reports',
					},{
						to:{name:'custom-forms-dashboard'},
						text: 'Form Dashboard',
					},{
						to:{name:'custom-forms-reports'},
						text:'Custom Forms'
					},{
						to:{name:'floor-traps-report'},
						text:'Floor Traps',          
					},{
						to:null,
						text:'Edit',
						active:true
					}];
					return this.filterReportBreadCrum(item);
				}else{
					var item = [{
						to:{name:'client-dashboard'},
						text: 'Dashboard',
					},{
						to:{name:'custom-forms'},
						text: 'Custom Forms',
					},{
						to:null,
						text:'Floor Traps',   
						active:true             
					}];
				}
				return item;
			},

			goBack(){
				var msg = 'Do you want to Go back without saving your report?';
				this.decisionAlert(msg)
				.then(result => {
					if (result.value) {
						localStorage.setItem('routeCFCheck','no');
						if(this.$route.params.id){
							this.$router.push({name:'floor-traps-report'})
						}else{
							this.$router.push({ name:'custom-forms' })
						}
					}
				})
			},
			
			CalculateTotalDefect(){
				for (var i = 0; i < this.checklistData.length; i++) {
					if(this.checklistData.length != this.items2.length){
						var obj = {
							building_name: '',
							total_defect: 0,
							total_actual_quantity: 0,
						};
						this.items2.push(obj);
					}

					this.items2[i].total_defect = 0;

					for (var j = 0; j < this.checklistData[i].data.length; j++) {
						this.items2[i].building_name = this.checklistData[i].data[j].building_name;
						if (this.checklistData[i].data[j].defect != null && this.checklistData[i].data[j].defect != '' && !isNaN(this.checklistData[i].data[j].defect)) {
							this.items2[i].total_defect = this.items2[i].total_defect + parseInt(this.checklistData[i].data[j].defect)
							this.checklistData[i].total_defect = this.items2[i].total_defect;
						}
					}
				}
			},

			CalculateTotalActualQuantity(){
				for (var i = 0; i < this.checklistData.length; i++) {

					this.items2[i].total_actual_quantity = 0;

					for (var j = 0; j < this.checklistData[i].data.length; j++) {
						if (this.checklistData[i].data[j].total_quantity != null && this.checklistData[i].data[j].total_quantity != '' && !isNaN(this.checklistData[i].data[j].total_quantity)) {
							this.items2[i].total_actual_quantity = this.items2[i].total_actual_quantity + parseInt(this.checklistData[i].data[j].total_quantity)
							this.checklistData[i].total_actual_quantity = this.items2[i].total_actual_quantity;
						}
					}
				}
			},

			buildingDisable(id){
				var building_ids = [];
				
				for (var i = 0; i < this.checklistData.length; i++) {
					if (this.checklistData[i].building != '') {
					building_ids.push(this.checklistData[i].building);
					}
				}

				if (building_ids.indexOf(id) >= 0) {
					return true;
				}else{
					return false;
				}
			}, 

			getFormDetails(){
				return this.$store.dispatch(POST_API, {
					data:{
						id:this.$route.params.id
					},
					api: '/api/get-ftr-report-detail'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
						this.showDismissibleAlert = true;
						window.scrollTo(0,0);
					} else {
						this.showDismissibleAlert = false;
						var data  = this.$store.getters.getResults.data;

						this.items2 = [];
						this.id = data._id;
						this.site = data.site;
						this.shift = data.shift;
						this.checklistData = data.checkListData;
						this.crew_signature = data.crew_signature;
						this.startTime = data.startTime;
						this.endTime = data.endTime;
						this.checked_by = data.checked_by;

						if (this.checklistData.length != 0){
							this.CalculateTotalDefect();
							this.CalculateTotalActualQuantity();
						}

						var role = this.$store.getters.currentUser.role;

						if (data.reject_to == role && ((data.level_one_user_role != role) && (data.level_two_user_role != role) && (data.level_three_user_role != role))) {
							this.ledit = true;
						} else if(((role == 'admin') || (role == 'administrator')) && ( (data.level_user_status != null) && (data.level_user_status.role == ''))){
							this.ledit = true;
						}

						this.getShiftData();
						this.getBuildings();

						return this.checklistData;
					}
				});
			},

			disabledDate(date){
				return date < moment(new Date(this.date)).subtract(1,'days').tz('Asia/Singapore');
			},

			checkdisabled(){
				if (this.ledit == true) {
					return false;
				}else if(this.disabledOptions == true){
					return true;
				}else{
					return false;
				}
			},

			isNumber($event) {
				let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
				if ($event.target.value.length >= 2) {
					$event.preventDefault();
				}else if(keyCode < 48 || keyCode > 57){
					$event.preventDefault();
				}
			},
		},

		mounted(){
			this.useDefault();
			this.allSites();
			this.siteChange();

			if(this.$route.params.id){
				this.disabledOptions = true;
				this.getFormDetails();
			}

		}
	}
</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import '@core/scss/vue/libs/vue-select.scss';
</style>